export default (names:string[]) => {
  let types:{[key:string]:string} = {};
  let actions:{[key:string]:Function} ={}

  function camelize(str:string) {
    return str.replace(/_/g, ' ').toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  names.map((item:string)=> {
    let funName:string = camelize(item);
    types[`${item}_REQUEST`] = `${item}_REQUEST`;
    types[`${item}_SUCCESS`] = `${item}_SUCCESS`;
    types[`${item}_ERROR`] = `${item}_ERROR`;
    
    actions[`${funName}Request`] = (payload: any) => ({
      type: types[`${item}_REQUEST`],
      payload
    });

    actions[`${funName}Success`] = (payload: any) => ({
      type: types[`${item}_SUCCESS`],
      payload
    });

    actions[`${funName}Error`] = (error: any) => ({
      type: types[`${item}_ERROR`],
      error
    });
  });

  return {
    types,
    actions
  }
}