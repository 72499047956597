import request from 'utils/request';
const axios:any = new request('global').request;

export async function walletAddressToNonce(payload:any):Promise<any> {
  return axios.post(`/v1/nonce`, payload);
}

export async function walletLogin(payload:any):Promise<any> {
  return axios.post(`/v1/tokens`, payload);
}

export async function walletLoginInfo(payload:any):Promise<any> {
  return axios.get(`/v1/me`, payload);
}