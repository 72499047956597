import React, { useState, useEffect } from 'react'
import Logo from 'pages/leadbest-official-website/assets/images/lcg-logo@3x.png'
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl, FormattedMessage } from 'react-intl'
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    root: {
        background: "rgba(0,19,36,1)",
        width: "100vw",
        height: "100vh",
        color: "#fff",
        "& .content-block": {
            flexDirection: "column",
            position: "absolute",
            top: "30%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            display: "flex",
            margin: "0px auto",
            alignItems: "center",
            [theme.breakpoints.down('sm')]: {
                top: "50%",
            }
        },
        "& .logo": {
            width: "90px",
            height: "auto",
            [theme.breakpoints.down('sm')]: {
                display: "none"
            }
        },
        "& .title": {
            margin: "12px 0px 10vh 0px",
            fontSize: "24px",
            color: "#fff",
            fontWeight: "normal",
            opacity: "0.8",
            [theme.breakpoints.down('sm')]: {
                display: "none"
            }
        },
        "& .process": {
            color: "#d9d9d9",
            fontSize: "12px",
            fontWeight: "normal",
            opacity: "0.8",
        },
        "& .loading-bar": {
            height: "2px",
            width: "150px",
            backgroundColor: "rgba(255,255,255,0.4)"
        },
        "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#f2c76d"
        }
    }
}))
const Loading = (props: any) => {

    const classes = useStyles()
    const global = useSelector((state: any) => state.global);
    const isEnglish = global.locale == 'en_us'

    return (
        <div className={classes.root}>
            <div className="content-block">
                <img className="logo" src={Logo} alt="logo" />
                <h1 className="title">
                    {isEnglish ? "Digital Empowerment Consulting Group" : "數位賦能顧問集團"}
                </h1>
                <h2 className="process">
                    {isEnglish ? "Empowering… Just a moment" : "賦能加速中... 請稍候"}
                </h2>
                <LinearProgress className="loading-bar" />
            </div>
        </div>
    )
}
export default Loading