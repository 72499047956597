import react, { useEffect, useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const ScrollHandler = (props: any) => {
    const theme = useTheme();
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [targetDom, setTargetDom] = useState<any>(null)
    const { location, children } = props;
    const target = location.hash

    useEffect(() => {
        if (!target) return;
        setTimeout(() => {
            const element = document.querySelector(target)
            setTargetDom(element)
        }, 100)
    }, [location, target]);

    useEffect(() => {
        if (!targetDom) return;
        setTimeout(() => {
            window.scrollTo({
                behavior: targetDom ? "smooth" : "auto",
                top: targetDom ? targetDom.offsetTop : 0
            });
        }, 100);
    }, [targetDom])

    return null;
};


export default withRouter(ScrollHandler);