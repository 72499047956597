import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname, key, hash, search } = useLocation();
    const [lastpage, setLastPage] = useState<any>(null)

    const params = new URLSearchParams(search);
    const anchor = Boolean(params.get("anchor"))
    useEffect(() => {
        if (anchor) return;
        if (pathname == lastpage && lastpage == "/empower") return;
        if (pathname == lastpage && lastpage == "/news") return;
        if (hash) return;

        window.scrollTo(0, 0);
    }, [pathname, key]);

    useEffect(() => {
        setLastPage(pathname)
    }, [pathname])

    return null;
}