import { combineReducers } from "redux";
import RoutesConfig from '../router/';
import global from './global/reducers';
// import users from './users/reducers';

const getReducerList = async function() {
  const basicRouter:any = RoutesConfig.find((route:any) => route.path == '/');
  const otherRouter:any = RoutesConfig.filter((route:any) => route.path !== '/');

  let routes:any = [...basicRouter.routes, ...otherRouter] || [];
  let reducers:any = {};

  routes = routes.filter((route:any) => {
    const fileName:any = route.path.replace('/','');
    return fileName !='404';
  });

  const routesReducer:any = await Promise.all(routes.map( async (route:any) => {
    const fileName:any = route.path.replace('/','');
    return import(`./${fileName}`)
    .then((module:any) => {
      return module.default
    })
  }))

  routesReducer.map((reducer:any) => {
    reducers = {...reducers, ...reducer};
  })

  return reducers;
}

export default async() => {
  let reducers:any = await getReducerList();
  return combineReducers({
    global,
    ...reducers
  });
};