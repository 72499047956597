import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'POST_CONTACT',
  'POST_EMAIL',
  'GET_BANNER'
];
export const SNACKBARLCG_REQUEST: string = 'SNACKBARLCG_REQUEST';
export const SET_SNACKBARLCG: string = 'SET_SNACKBARLCG';

const createActions: any = actionsHelper(names);

const setSnackbarLCG = (payload: any) => ({
  type: SET_SNACKBARLCG,
  payload
});
const snackbarLCGRequest = (payload: any) => ({
  type: SNACKBARLCG_REQUEST,
  payload
});

export default {
  ...createActions.types,
  ...createActions.actions,
  SNACKBARLCG_REQUEST,
  SET_SNACKBARLCG,
  setSnackbarLCG,
  snackbarLCGRequest
}
