import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware  from 'redux-saga';
import getRootSaga  from '../sagas/';
import getReducer from "./reducer";

import logger from "redux-logger";

  

  export default async() => {
    let reducer:any = await getReducer();
    let rootSaga:any = await getRootSaga();
    // 创建saga中间件
    const sagaMiddleware = createSagaMiddleware();
    const middleWares = window.Config.NODE_ENV == 'prod' ? [sagaMiddleware] : [sagaMiddleware, logger];
    const middlewareEnhancer = applyMiddleware(...middleWares);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers:any = compose(...enhancers);

    // 创建存储容器
    const store = createStore(reducer, composedEnhancers);

    sagaMiddleware.run(rootSaga);
    return store;
  };
