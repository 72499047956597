import './assets/scss/App.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './stores'
import { HelmetProvider } from 'react-helmet-async';

(async() => {
  let stores:any = await store();
  ReactDOM.render(
    <Provider store={stores}>
      <HelmetProvider>
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Provider>
  , document.getElementById('root')
  );
})()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();