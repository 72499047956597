import {
  createTheme,
  alpha
} from '@material-ui/core/styles';
import { AlertClassKey } from '@material-ui/lab/Alert';

declare module "@material-ui/core/styles/createPalette" {
  interface TypeBackground {
    default: string;
    paper: string;
    surface: string;
    onSurface: string;
    top: string;
  }
  interface TypeText {
    darkPrimary1: string;
    darkPrimary2: string;
    darkPrimary3: string;
    darkPrimary4: string;
    lightPrimary1: string;
    lightPrimary2: string;
    lightPrimary3: string;
    lightPrimary4: string;
    gary1: string;
    gary2: string;
    gary3: string;
    gary4: string;
    gary5: string;
    gary6: string;
    gary7: string;
    gary8: string;
    gary9: string;
    gary10: string;
  }
  interface Palette {
    default: Palette['primary'];
    border: Palette['primary'];
    background: Palette['background'],
    text: Palette['text']
  }
  interface PaletteOptions {
    default?: PaletteOptions['primary'];
    border?: PaletteOptions['primary'];
    background?: Partial<TypeBackground>,
    text?: Partial<TypeText>
  }
}

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#f7931a',
      light: '#ffc451',
      dark: '#be6500',
      contrastText: '#fff'
    },
    secondary: {
      main: '#4b546a',
      light: '#D9D9D9',
      dark: '#383838',
      contrastText: '#fff'
    },
    default: {
      main: '#434858',
      contrastText: '#fff'
    },
    success: {
      main: '#4DCC76'
    },
    warning: {
      main: '#F8BC44'
    },
    error: {
      main: '#ED5454'
    },
    info: {
      main: '#2D62F2'
    },
    divider: '#4b546a',
    border: {
      main: '#4b546a',
      light: '#60c2ff',
      dark: '#62657b'
    },
    background: {
      default: '#18181F',
      paper: '#fff',
      surface: '#fff',
      onSurface: '#242733',
      top: '#353A4C'
    },
    text: {
      secondary: '#aaa',
      darkPrimary1: 'rgba(255,255,255,1)',
      darkPrimary2: 'rgba(255,255,255,0.85)',
      darkPrimary3: 'rgba(255,255,255,0.65)',
      darkPrimary4: 'rgba(255,255,255,0.25)',
      lightPrimary1: 'rgba(0,0,0,1)',
      lightPrimary2: 'rgba(0,0,0,0.85)',
      lightPrimary3: 'rgba(0,0,0,0.65)',
      lightPrimary4: 'rgba(0,0,0,0.25)',
      gary1: '#fff',
      gary2: '#F2F2F2',
      gary3: '#D9D9D9',
      gary4: '#AAAAAA',
      gary5: '#8C8C8C',
      gary6: '#707070',
      gary7: '#545454',
      gary8: '#383838',
      gary9: '#1E1E1E',
      gary10: '#000000',
    },
    action: {
      disabled: alpha('#383838', 0.25)
    }
  }
});

const MuiTheme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Noto Sans TC',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: "60px",
      fontWeight: "bold",
      '@media (max-width: 960px)': {
        fontSize: "44px",
      },
      '@media (max-width: 600px)': {
        fontSize: "26px",
      },
    },
    h2: {
      fontSize: "32px",
      fontWeight: "bold",
      '@media (max-width: 960px)': {
        fontSize: "30px",
      },
      '@media (max-width: 600px)': {
        fontSize: "28px",
      },
    },
    h3: {
      fontSize: "28px",
      fontWeight: "bold",
      '@media (max-width: 960px)': {
        fontSize: "24px",
      },
      '@media (max-width: 600px)': {
        fontSize: "20px",
      },
    },
    h4: {
      fontSize: "24px",
      '@media (max-width: 960px)': {
        fontSize: "21px",
      },
      '@media (max-width: 600px)': {
        fontSize: "18px",
      },
      '@media (max-width: 325px)': {
        fontSize: "16px",
      },
    },
    h5: {
      fontSize: "20px",
      fontWeight: "bold",
      '@media (max-width: 960px)': {
        fontSize: "19px",
      },
      '@media (max-width: 600px)': {
        fontSize: "18px",
      },
    },
    h6: {
      fontSize: "16px",
      '@media (max-width: 960px)': {
        fontSize: "15px",
      },
      '@media (max-width: 600px)': {
        fontSize: "14px",
      },
      "@media(max-width:325px)": {
        fontSize: "12px",
      }
    },
    body1: {
      fontWeight: 500,
      
    }
  },
  palette: defaultTheme.palette,

});

export default MuiTheme;