import actionsHelper from 'utils/actionsHelper';
const names: any = [
  'GET_EMPOWER',
  'GET_EMPOWERDETAILS',
];

const createActions: any = actionsHelper(names);

export default {
  ...createActions.types,
  ...createActions.actions
}
