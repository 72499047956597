export default [
  {
    path: '/',
    component: 'layouts/default',
    redirect: '',
    routes: [
      {
        title: 'title.404.notfound',
        Icon: 'components/Icons/IconBlockchain',
        path: '/404',
        component: '404',
        order: 999,
        routes: [],
        navList: false,
        breadcrumb: false,
      },
      {
        title: 'leadbest-official-website.title',
        Icon: 'AccountCircleOutlinedIcon',
        path: '/leadbest-official-website',
        authorize: ['investor', 'enterprise'],
        breadcrumbLink: false,
        order: 1,
        isProject: true,
        routes: [
          {
            title: '首頁',
            path: '/leadbest-official-website/',
            component: 'leadbest-official-website/Index',
            navList: false,
            authorize: ['default', 'investor', 'enterprise'],
            routes: [
              {
                title: 'title.404.notfound',
                Icon: 'components/Icons/IconBlockchain',
                path: '/leadbest-official-website/404',
                component: 'leadbest-official-website/404',
                order: 999,
                routes: [],
                navList: false,
                breadcrumb: false,
              },
              {
                title: '關於集團',
                path: '/leadbest-official-website/about',
                component: 'leadbest-official-website/About',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
              {
                title: '集團理念',
                path: '/leadbest-official-website/scribing',
                component: 'leadbest-official-website/Scribing',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
              {
                title: '服務項目',
                path: '/leadbest-official-website/service',
                component: 'leadbest-official-website/Service',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
              {
                title: '賦能案例',
                path: '/leadbest-official-website/empower',
                component: 'leadbest-official-website/Empower/Index',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [
                  {
                    title: '賦能案例 詳細資訊',
                    path: '/leadbest-official-website/empower/:id',
                    component: 'leadbest-official-website/Empower/Details',
                    routes: [],
                  },
                ],
              },
              {
                title: '新聞列表',
                path: '/leadbest-official-website/news',
                component: 'leadbest-official-website/News/Index',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [
                  {
                    title: '新聞內頁',
                    path: '/leadbest-official-website/news/:id',
                    component: 'leadbest-official-website/News/Details',
                    routes: [],
                  },
                ],
              },
              {
                title: '新聞關鍵字/分類',
                path: '/leadbest-official-website/news-collection',
                component: 'leadbest-official-website/News/NewsCollection',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
              {
                title: '聯絡我們',
                path: '/leadbest-official-website/contact',
                component: 'leadbest-official-website/Contact',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
              {
                title: '加入我們',
                path: '/leadbest-official-website/join',
                component: 'leadbest-official-website/Join',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
              // 因 google 搜尋結果會有 /leadbest-official-website/index.html，需要導向首頁
              {
                title: '首頁',
                path: '/leadbest-official-website/index.html',
                component: 'leadbest-official-website/Index',
                navList: false,
                authorize: ['default', 'investor', 'enterprise'],
                routes: [],
              },
            ],
          },
        ],
      },
    ],
    isProject: true,
  },
];
