import React, { useState } from 'react';
import Arrow from './../../pages/leadbest-official-website/assets/images/arrow-top.png'
import Contact from './../../pages/leadbest-official-website/assets/images/contact.png'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .button": {
            transition: "0.3s",
            position: "fixed",
            width: "32px",
            height: "32px",
            right: "40px",
            cursor: "pointer",
            minWidth: "unset",
            background: "rgba(56,69,84)",
            zIndex: "100",
            "&.scroll": {
                bottom: "40px",
            },
            "&.contact": {
                bottom: "87px",
            }
        },
        "& .scroll-icon": {
            width: "17px",
            height: "10px",
        },
        "& .contact-icon": {
            width: "16px",
            height: "16px"
        }
    }
}))

const ScrollButton = () => {
    const classes = useStyles()
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);
    return (
        <div className={classes.root}  >
            <Button className="button contact" component={Link} to="/contact" style={{ opacity: visible ? '0.6' : '0', zIndex: visible ? 100 : -1 }} aria-label="scoll to the top ">
                <img className="contact-icon" src={Contact} alt="" />
            </Button>
            <Button className="button scroll" onClick={scrollToTop} style={{ opacity: visible ? '0.6' : '0', zIndex: visible ? 100 : -1 }} aria-label="scoll to the top ">
                <img className="scroll-icon" src={Arrow} alt="" />
            </Button>
        </div>
    );
}

export default ScrollButton;