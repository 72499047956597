import actionsHelper from 'utils/actionsHelper';
import {DialogOptions,AlertOptions, SnackbarOptions} from 'lbComponents';

const names:string[] = [
  'POST_UPLOAD_FILE',
  'GET_USER_INFO',
  'GET_CAPTCHA',
  'CHANGE_TRADER_LEVEL',
  'GET_META',
  'DELETE_USER_ACCESS_TOKEN',
  'START_UP',
  'WALLET_ADDRESS_TO_NONCE',
  'WALLET_LOGIN',
  'WALLET_LOGIN_INFO'
];

const createActions = actionsHelper(names);
export const CHANGE_LANGUAGE:string = 'CHANGE_LANGUAGE';
export const CHANGE_USER_IDENTITY:string = 'CHANGE_USER_IDENTITY';
export const TOOGLE_DIALOG:string = 'TOOGLE_DIALOG';
export const SET_TOOGLE_DIALOG:string = 'SET_TOOGLE_DIALOG';

export const SNACKBAR_REQUEST:string = 'SNACKBAR_REQUEST';
export const SET_SNACKBAR:string = 'SET_SNACKBAR';

export const ALERT_REQUEST:string = 'ALERT_REQUEST';
export const SET_ALERT:string = 'SET_ALERT';

export const SET_BREADCRUMB_NAME = 'SET_BREADCRUMB_NAME';
export const SET_SOCKET_DATA = 'SET_SOCKET_DATA';

export const LOG_OUT:string = 'LOG_OUT';

export const SET_MODULE_ACL:string = 'SET_MODULE_ACL'
export const SET_FEATURE_ACL:string = 'SET_FEATURE_ACL'

export const WALLET_LOGOUT:string = 'WALLET_LOGOUT'

const changeLanguage = (payload: any) => ({
  type: CHANGE_LANGUAGE,
  payload
});

const changeUserIdentity = (payload: any) => ({
  type: CHANGE_USER_IDENTITY,
  payload
})

const toogleDialog = (payload: DialogOptions) => ({
  type: TOOGLE_DIALOG,
  payload
});

const setToogleDialog = (payload: any) => ({
  type: SET_TOOGLE_DIALOG,
  payload
});

const snackbarRequest = (payload: any) => ({
  type: SNACKBAR_REQUEST,
  payload
});

const setSnackbar = (payload: SnackbarOptions) => ({
  type: SET_SNACKBAR,
  payload
});

const alertRequest = (payload: AlertOptions) => ({
  type: ALERT_REQUEST,
  payload
});

const setAlert = (payload: AlertOptions) => ({
  type: SET_ALERT,
  payload
});

const setBreadcrumbName = (payload: any) => ({
  type: SET_BREADCRUMB_NAME,
  payload
});

const setSocketData = (payload: any) => ({
  type: SET_SOCKET_DATA,
  payload
});

const logout = () => ({
  type: LOG_OUT
});

const walletLogout = () => ({
  type: WALLET_LOGOUT
});



const setModuleAcl = (payload: any) => ({
  type: SET_MODULE_ACL,
  payload
});

const setFeatureAcl = (payload: any) => ({
  type: SET_FEATURE_ACL,
  payload
});

const actions:{
  [name:string]:any
} = {
  CHANGE_LANGUAGE,
  changeLanguage,
  CHANGE_USER_IDENTITY,
  changeUserIdentity,
  TOOGLE_DIALOG,
  toogleDialog,
  SET_TOOGLE_DIALOG,
  setToogleDialog,
  SNACKBAR_REQUEST,
  SET_SNACKBAR,
  snackbarRequest,
  setSnackbar,
  setBreadcrumbName,
  SET_BREADCRUMB_NAME,
  SET_SOCKET_DATA,
  setSocketData,
  LOG_OUT,
  logout,
  WALLET_LOGOUT,
  walletLogout,
  SET_MODULE_ACL,
  setModuleAcl,
  SET_FEATURE_ACL,
  setFeatureAcl,
  alertRequest,
  setAlert,
  ALERT_REQUEST,
  SET_ALERT,
  ...createActions.types,
  ...createActions.actions
}

export default actions