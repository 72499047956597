import React, { useEffect } from 'react';
import Loadable, {LoadableComponent} from '@loadable/component'
import { useDispatch } from 'react-redux';
import RoutesConfig from 'router';
import GlobalActions from 'stores/global/actions';
import { Route, Switch, Prompt, matchPath, Redirect, useRouteMatch } from "react-router-dom";
import { matchRoutes } from 'react-router-config';
import { useSelector } from 'react-redux';
import Languages from 'locales';
import _ from 'lodash';

import {
  MuiThemeProvider
} from '@material-ui/core/styles';
import {
  CssBaseline,
  LinearProgress
} from '@material-ui/core';

import BasicTheme from './themes'; 

const basicRouter = RoutesConfig.find((route) => route.path === '/') ;
// const firstRouter = basicRouter && basicRouter.routes && basicRouter.routes.filter((route) =>  route.path !== '/404' && route.path !== '/account')[0] ;

const layoutsLoadable: {
  [compoent:string]: LoadableComponent<any>
} = {}

RoutesConfig.map((route: RouterType.RouteItem) => {
  const routeComponent:string = route.component || '';
  return layoutsLoadable[routeComponent] = Loadable(() => import(`layouts/${routeComponent.replace('layouts/', '')}`),
  {
    fallback: <LinearProgress/>
  });
});

const pluginsLoadable:{
  [rootRouteName:string]:{
    [moduleName:string]: any
  }
} = {}



RoutesConfig.map((rootRoute: any) => {
  const rootName = (rootRoute.path === '/') ? 'basic' : rootRoute.path.substr(1);
  if(!pluginsLoadable[rootName]) {
    pluginsLoadable[rootName] = {}
  }

  if (rootName != 'basic') {
    pluginsLoadable[rootName][rootName] = require(`plugins/${rootName}`);
  } else {
    rootRoute.routes.map((moduleRoute: any) => {
      const moduleName = moduleRoute.path.substr(1);
      if (moduleName == '404') {
        return;
      }
      pluginsLoadable[rootName][moduleName] = require(`plugins/${moduleName}`);
    })
  }

  
});

const Routes = ({location}: any) => {
  const installedUserModule = RoutesConfig.map((route:any) => route.path).indexOf('/user') >= 0;
  const accountAuth:any = JSON.parse(localStorage.getItem('account-auth') || '[]') || {};
  const isUserLogined:boolean = accountAuth && accountAuth?.token;
  const rootMatchPath:any = []
  return (
    <>
      {
        RoutesConfig.map((route: RouterType.RouteItem, index: number) => {
          const projectRoute:any = (route.routes || []).filter((route:any) => route.isProject).map((route:any) => `(${route.path})`)
          const rootName = (route.path === '/') ? 'basic' : route.path.substr(1);
          const routePath:string = route.path || '';
          const routeComponentPath:string = route.component || '';
          // const path:string = routePath.replace('/', '');
          // const paths = RoutesConfig.filter((route: RouterType.RouteItem) => route.path != '/').map((route: RouterType.RouteItem) => route.path);
          const LangsKeys = _.values(Languages);
          const langPathRegex = LangsKeys.length > 1 ? _.chain(LangsKeys).reduce((a,b) => a.keys +  `|${b.keys}`).value() : LangsKeys[0].keys;
          const rootLangPath = [routePath,`/:lang(|${langPathRegex}${routePath == '/' ? '|404' : ''})${routePath == '/' ? '' : routePath}`];
          
          const langPaths:string[] = []
          let langRoutes = _.cloneDeep(route.routes);
          langRoutes = _.chain(langRoutes)
          .reverse()
          .filter((item:any) => {
            const addLangPath = (langRoute:any) => {
              langRoute.path = [langRoute.path.replace(new RegExp(projectRoute.join(''), 'g'),'') || `/`, `/:lang(|${langPathRegex})${langRoute.path.replace(new RegExp(projectRoute.join(''), 'g'),'')}`];;
              if(langPaths.indexOf(langRoute.path[0]) < 0) {
                (langRoute.routes || []).map((subRoute:any) => {
                  return addLangPath(subRoute);
                })
                langPaths.push(langRoute.path[0])
                return langRoute;
              }
            }
            return addLangPath(item)
          }).value();

          langRoutes = _.sortBy(langRoutes, ['order']).reverse()

          const matchedRoutes = matchRoutes(langRoutes, location.pathname);
          const existRoute:any = matchedRoutes.find((item:any) => item.match.isExact);
    
          if(existRoute) {
            rootMatchPath.push(existRoute.match)
          }
          rootMatchPath.push(matchPath(location.pathname, {
            path: rootLangPath,
            exact: rootName == 'basic',
            strict: true
          }))
          /* const routeMatch:any = matchPath(location.pathname, {
            path: [`/:lang(|${langPathRegex})${routePath}`, routePath]
          });
          const userLangKey = _.findKey(Languages, function(lang:any) { 
            const keys = lang.keys.split('|');
            return keys.indexOf(routeMatch && routeMatch.params.lang || navigator.language) >= 0
          }); */
          return (
           
              <React.Fragment key={index} >
                <Route exact={rootName == 'basic' && !existRoute} path={rootLangPath} render={props => {
                  const RouteComponent:LoadableComponent<React.ComponentProps<any>> = layoutsLoadable[routeComponentPath];
                  return (
                    <RouteComponent rootPath={rootLangPath} langRoutes={langRoutes} routes={route.routes} redirect={route.redirect || ''} modulesPlugin={pluginsLoadable[rootName]} {...props} />
                    // <RouteComponent {...props} routes={basicRouter && basicRouter.isProject ? firstRouter && firstRouter.routes : route.routes} redirect={route.redirect || ''} />
                  )
                }} />
                
              </React.Fragment>
          )
        })
      }
      {
         rootMatchPath.filter((item:any) => item === null ).length >= rootMatchPath.length &&  <Redirect from='*' to={installedUserModule && !isUserLogined ? '/user/login' : `/404`} />
      }
    </>
  )
}

const App = () => {
  const dispatch = useDispatch();
  const startUp = useSelector((state:any) => state.global.startUp);
  
  useEffect(() => {
    dispatch(GlobalActions.startUpRequest());
  }, []);

  return (
    <MuiThemeProvider theme={BasicTheme}>
      <div className="App">
        <CssBaseline />
        {
          Object.keys(pluginsLoadable).map((rootName) => {
            return Object.keys(pluginsLoadable[rootName]).map((moduleName) => {
              if (pluginsLoadable[rootName][moduleName].default.methods) {
                return pluginsLoadable[rootName][moduleName].default.methods.map((plugin: any) => {
                  return React.createElement(plugin, {key:moduleName})
                })
              }
            })
          })
        }
        <Prompt message={(location: any) => {
          if (location.pathname.indexOf('http') >= 0) {
            window.open(location.pathname.slice(location.pathname.indexOf('http')));
          }
          return location.pathname.indexOf('http') < 0;
        }} />
        {
          startUp.loaded &&
          <Switch>
            <Routes />
          </Switch>
        }
        
      </div>
    </MuiThemeProvider>
  )
}

export default App;
