import { takeLatest, all, takeLeading } from 'redux-saga/effects'
import GlobalActions from 'stores/global/actions';
import RoutesConfig from '../router/';
import { 
  changeLanguageSaga, 
  toogleDialogSaga, 
  snackbarSaga,
  alertSaga,
  logoutSaga,
  uploadFileSaga,
  getCaptchaSaga,
  getMetaSaga,
  deleteUserAccessTokenSaga,
  startUpSaga,
  walletAddressToNonceSaga,
  walletLoginSaga,
  getUserInfoSaga
} from './globalSaga';


const getTakes = async function() {
  const basicRouter:any = RoutesConfig.find((route:any) => route.path == '/');
  const otherRouter:any = RoutesConfig.filter((route:any) => route.path !== '/');

  let routes:any = [...basicRouter.routes, ...otherRouter] || [];
  let takes:any = [];

  routes = routes.filter((route:any) => {
    const fileName:any = route.path.replace('/','');
    return fileName !='404';
  });

  const routesTakes:any = await Promise.all(routes.map( async (route:any) => {
    const fileName:any = route.path.replace('/','');
    return import(`./${fileName}`)
    .then((module:any) => {
      return module.default
    })
  }))

  routesTakes.map((take:any) => {
    takes = [...takes, ...take];
  })

  return takes
}

export default async() => {
  let takes:any = await getTakes();

  return function* rootSaga() {
    yield all([
      takeLeading(GlobalActions.CHANGE_LANGUAGE, changeLanguageSaga),
      takeLatest(GlobalActions.TOOGLE_DIALOG, toogleDialogSaga),
      takeLatest(GlobalActions.SNACKBAR_REQUEST, snackbarSaga),
      takeLatest(GlobalActions.ALERT_REQUEST, alertSaga),
      takeLatest(GlobalActions.LOG_OUT, logoutSaga),
      takeLatest(GlobalActions.POST_UPLOAD_FILE_REQUEST, uploadFileSaga),
      takeLatest(GlobalActions.GET_CAPTCHA_REQUEST, getCaptchaSaga),
      takeLatest(GlobalActions.GET_META_REQUEST, getMetaSaga),
      takeLatest(GlobalActions.DELETE_USER_ACCESS_TOKEN_REQUEST, deleteUserAccessTokenSaga),
      takeLatest(GlobalActions.START_UP_REQUEST,startUpSaga),
      takeLatest(GlobalActions.WALLET_ADDRESS_TO_NONCE_REQUEST, walletAddressToNonceSaga),
      takeLatest(GlobalActions.WALLET_LOGIN_REQUEST,walletLoginSaga),
      takeLatest(GlobalActions.GET_USER_INFO_REQUEST, getUserInfoSaga),
      ...takes
    ])
  }

}

