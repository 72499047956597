const initialState = {
  locale: 'zh_tw',
  languageSettings: {
    isInitial:false
  },
  globalDialog: {
    confirm: false,
    visible: false,
    close: false,
    fullScreen: false,
    mobileFullScreen:false,
    mobileVertical:'', //[flex-start, center, flex-end]
    title: '',
    titleComponent:null,
    contentComponent: null,
    dialogComponent: null,
    loading: false,
    confirmAction: '',
    confirmVariant: 'contained',
    confirmColor: 'primary',
    confirmText: 'action.confirm',
    cancelAction: '',
    cancelVariant: 'outlined',
    cancelColor: 'secondary',
    cancelText: 'action.cancel',
    maxWidth: 'xs' //[xs,sm,md,lg]
  },
  snackbar: {
    visible: false
  },
  alert: {
    visible: false,
    position: 'fixed',
    severity: 'success',
    variant: 'filled',
    Icon: null,
    close: true,
    closeAction: null,
    vertical: 'top',
    content: ''
  },
  breadcrumbName: {},
  uploadFile: {
    loading: false,
    loaded: false,
    data: {}
  },
  sharedData: {
    user: {
      loading: false,
      loaded: false,
      updated: false,
      data: {
        current: 'default'
      }
    },
    socket: <any> {}
  },
  captcha: {
    loading: false,
    loaded: false,
    data: {}
  },
  moduleAcl:{},
  meta: {
    loading: false,
    loaded: false,
    data: {},
  },
  featureAcl: {},
  deleteUserAccessToken: {
    loading: false,
    loaded: false,
  },
  startUp: {
    loading:false,
    loaded: false
  },
  loginNonce: {
    loading: false,
    loaded: false,
    data: {},
  },
  walletConnect: {
    loading: false,
    loaded: false,
    data: {},
  }
};

export type GlobalState = typeof initialState

export default initialState