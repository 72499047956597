import en_us from 'locales/basic/en_us.json';
import zh_tw from 'locales/basic/zh_tw.json';
const languages: any = {
  zh_tw: {
    locale: 'zh_tw',
    label: '\u7E41\u4E2D',
    id: 0,
    keys: 'tw|hant|zh_tw|zh_TW|zh-tw|zh-TW',
    messages: zh_tw,
  },
  en_us: {
    locale: 'en_us',
    label: 'EN',
    id: 1,
    keys: 'en|en_us|en_US|en-us|en-US',
    messages: en_us,
  },
};
export default languages;
